import React from 'react';

import Index, { IndexProps } from 'components/Layout';
import SEO from 'components/seo';
import PageWrapper from 'components/PageWrapper';
import PrivacyPolicy from 'components/PrivacyPolicy';

const PrivacyPolicyPage: React.FC<IndexProps> = ({ pageContext }) => (
  <Index pageContext={pageContext}>
    <SEO pageTitleTermPath="privacyPolicy" />
    <PageWrapper withBottomBackground>
      <PrivacyPolicy />
    </PageWrapper>
  </Index>
);

export default PrivacyPolicyPage;
