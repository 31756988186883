import React from 'react';

import { useIntl } from 'react-intl';
import styles from './PrivacyPolicy.module.scss';
import EnglishText from './EnglishText';
import FrenchText from './FrenchText';

const PrivacyPolicy: React.FC = () => {
  const { locale } = useIntl();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {locale === 'fr' ? (
          <FrenchText />
        ) : (
          <EnglishText />
        )}
      </div>
    </div>

  );
};

export default PrivacyPolicy;
